@import "./variables";

h2 {
  color: $_tf_input_main;
}

.mat-caption {
  font: 300 10px / 12px Montserrat !important;
  color: rgb(138, 173, 192, 0.7) !important;
  // font-weight: 100;
}

.mat-caption-12 {
  color: #4EA8B0;
  font-size: 12px !important;
}

.mat-caption-12-bg {
  padding: 2px 6px;
  background-color: #1E2A33;
  color: #4EA8B0;
  font-size: 12px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px !important;

}

.fs20 {
  font-size: 20px;
}


.cursorHelp {
  cursor: help;
}

.tlc {
  text-align: center !important;
}

.tlr {
  text-align: right !important;
  ;
}
