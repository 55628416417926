/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600&display=swap');
@import "./variables";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
// @import "primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.css";
// // import Quill styles in styles.scss
// @import "../node_modules/quill/dist/quill.core.css";
// @import "../node_modules/quill/dist/quill.snow.css";
html,
body {
  height: 100%;
}

body {
  margin: 0;
  // font-family: "Orbitron", sans-serif;
  font-family: 'Montserrat', sans-serif !important;
  // background-color: rgb(12,20,22);
  background-color: $_bg_dark_100;
  // background-color: rgb(23,24,26);
  // background: rgb(12,20,22);
  // background: radial-gradient(circle, rgba(12,20,22,1) 0%, rgba(7,13,13,1) 100%);
  width: 100%;
  padding: 0px;
  overflow: hidden;
  color: #ffffff;
  scrollbar-color: rgba(0, 0, 0, 0);
}

.bodyLight {
  background-color: $_bg_light_100;
  color: $_font-dark
}

::-webkit-scrollbar-track {
  background: rgba($color: #194744, $alpha: 0);
}

::-webkit-scrollbar-thumb {
  background-color: rgba($color: #ffffff, $alpha: 0);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($color: #194744, $alpha: 0);
}

::-webkit-scrollbar {
  height: 0px;
  width: 2px;
  // border: 1px solid #d5d5d5;
  border: none;
}
.mat-mdc-tooltip {
  color: unset!important;
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: unset!important;
  --mdc-plain-tooltip-supporting-text-color: unset!important;
}

.sideShadow {
  -webkit-box-shadow: 3px 0px 7px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 0px 7px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 0px 7px -4px rgba(0, 0, 0, 0.75);
}

.nav {
  position: fixed;
  width: 100%;
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // align-items: center;
  // -webkit-box-pack: justify;
  // -ms-flex-pack: justify;
  // justify-content: space-between;
  height: 64px;
  // padding: 0 32px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .02), 0 10px 35px 0 rgba(12, 33, 50, .2);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .02), 0 10px 35px 0 rgba(12, 33, 50, .2);

  //  transform: translateY(0);
  transition: all .25s ease-out;
  opacity: 1;
}
.fs-rob {
  font-family: 'Roboto';
  font-stretch: condensed;
}
.toolbar-spacer {
  flex: 1 1 auto;
}

*:focus {
  outline: none !important;
  border: 0 !important;
}

.select-cg {
  width: -webkit-fill-available;
  // width: 220px;
  height: 42px;
  padding-left: 10px;
  padding-right: 20px;
  border-radius: 0px;
  //  border: 1px solid rgb(138,173,192,0.2);
  border: 1px solid rgba(188, 191, 168, 0.7);
  border-radius: 0px;
  // outline: none;
  font-size: 14px;
  // color: rgb(138,173,192,1)!important;
  color: rgb(131, 135, 106) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.formControl {
  position: relative;
  float: left;
  width: 100%;

  display: block;
  padding: 6px 10px 6px 10px;

  border: 1px solid rgb(138, 173, 192, 0.2);
  border-color: $_dark_menu_item_background_hover_light !important;
  // border-color: $_dark_menu_item_background_active;
  border-radius: 4px;
  outline: none;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 0.09rem;
  color: $_form_control_color !important;
  background-color: rgba($color: #444444, $alpha: 0.0);
  transition: all 0.25s ease-out;

}

.formControlError {
  position: relative;
  float: left;
  width: 100%;

  display: block;
  padding: 6px 10px 6px 10px;
  // padding: 10px;


  // border-radius: 0px;
  outline: none;
  font-size: 14px;

  color: $_warn !important;
  background-color: rgba($color: $_warn, $alpha: 0.0);

  border: 1px solid $_warn !important;
  transition: border 0.25s ease-out;
}

.error {
  color: $_warn !important;
}

.textFieldH40 {
  height: 40px !important;
}

.textFieldH35 {
  height: 35px !important;
}

.formControl:focus {
  // color:rgba($color: #444444, $alpha: 0.9);
  border: 1px solid $_dark_menu_item_background_active !important;
  // border: 1px solid rgb(138,173,192,0.7)!important;
  // border-color: $_dark_menu_item_background_active;
  transition: all 0.45s ease-out;
  // transition: color 0.45s ease-out;
}

// .formControl:focus {
//   // color:rgba($color: #444444, $alpha: 0.9);
//   // border: 1px solid rgb(138,173,192,0.7)!important;
//   border: 1px solid rgba(188, 191, 168, 0.46);
//   transition: border 0.25s ease-out;
//   // transition: color 0.45s ease-out;
// }
.formControlError:focus {
  // color:rgba($color: #444444, $alpha: 0.9);
  border: 1px solid $_warn !important;
  transition: border 0.25s ease-out;
  // transition: color 0.45s ease-out;
}

// .mat-caption{
//   color: rgb(138,173,192,0.7);
//   // font-weight: 100;
// }
.textfield100 {
  width: 100%;
}

.textfield50 {
  width: 50%;
}

.textfield70 {
  width: 70%;
}

.textfield30 {
  width: 30%;
}

.textfield35 {
  width: 35%;
}

.textfield20 {
  width: 20%;
}

.textfield15 {
  width: 15%;
}

.textfield40 {
  width: 40%;
}






.f_l {
  float: left;
}

mat-card {
  border-radius: 0px !important;
  background-color: rgba($color: #0c1416, $alpha: 0.75) !important;
  // background-color: #0c1416;
  // background: #0c1416;
  background: radial-gradient(circle, #0c1416 0%, #070d0d 100%)!important;
  // -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  //   box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
}

.mat-card-light {
  // background-color: rgba($color: #0c1416, $alpha: 0.1) !important;

  // background: radial-gradient(circle, $_bg_light_100 0%, $_bg_light_50 100%);
  background-color: #fff !important;
  background: #fff !important;
  border-radius: 6px;
  box-shadow: 0 0 0 rgba(12, 33, 50, .1), 0 0 0 rgba(0, 0, 0, .07);
}

.backdrop-background {
  // filter: blur(10px)!important;
  background-color: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(15px);
}

.backdrop-background-light {
  // filter: blur(10px)!important;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.backdrop-2 {
  background-color: rgba($color: $_bg_dark_80, $alpha: 0.75) !important;
  backdrop-filter: blur(5px);
}

.fs_backdrop {

  // filter: blur(10px)!important;
  /*   background-color: rgba(63,168,175,0.21); */
  background-color: rgba(0, 0, 0, 0.75) !important;
  backdrop-filter: blur(8px);
  /*   -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important; */

}

.adminPanelClass {
  .mat-dialog-container {
    background-color: #0c1416;
    background: #0c1416;
    background: radial-gradient(circle, #0c1416 0%, #070d0d 100%);
    // background-color: rgba(63,168,175,1)!important;
    // background: rgba(63,168,175,1)!important;
    // backdrop-filter: blur(8px);
    /*  background-color: rgba(0,0,0,0.36)!important; */
    /*   background: rgba(0,0,0,0.26)!important; */
  }
}

.verySMGutter {
  padding-top: 4px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
  padding-bottom: 4px !important;
}

.xs-gutter {
  padding-top: 0px !important;
  padding-right: 1px !important;
  padding-left: 1px !important;
  padding-bottom: 2px !important;
}

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* height: 93px; */
  z-index: 0;
}

.logo-container {

  margin-left: 53px;
  margin-top: 17px;
  width: 239px;
  height: 76px;
  display: inline-flex;
}

.logo {
  width: 239px;
  height: 76px;

}

.mainNav {
  display: inline-flex;
  height: 76px;
  margin-top: 17px;
  width: 200px;
  float: right;
}

.main-nav-btn {
  cursor: pointer;

  color: #DFE6BA;
  // font-weight: 200;
  -webkit-user-select: none;
  transition: color 0.15s ease-in-out;
}

.main-nav-btn:hover {
  color: #C14225;
  transition: color 0.15s ease-in-out;
}

.digital {
  // font-family: "Montserrat", sans-serif;
  // font-weight: 200;
  // font-size: 14px;
}

mat-card-subtitle {
  color: #4EA8B0 !important;
  font-weight: 300 !important;
  text-transform: uppercase;
  margin-bottom: 4px !important;
}

.uc {
  text-transform: uppercase;
}

// .select-form-field{
//  /*  color: #DFE6BA!important; */
//   border-radius: 0px!important;
// }
// mat-form-field-appearance-outline{
//   border-radius: 0px!important;
// }
// .mat-form-field-appearance-outline{
//   border-radius: 0px!important;
// }

.employee-copy {
  color: #4EA8B0;
  font-size: 12px;
  font-weight: 200;
  padding-bottom: 5px;

}

.listitem-copy {
  color: #4EA8B0;
  font-size: 12px;
  font-weight: 200;
  padding-bottom: 5px;
}

.main-menu-btn {
  cursor: pointer;
  -webkit-user-select: none;
  color: rgb(138, 173, 192, 0.5);
  font-size: 12px;
  font-weight: 200;
  padding-bottom: 5px;
  transition: all 0.35s ease-out;
}

.main-menu-btn:hover {
  color: rgb(138, 173, 192, 0.9);
  transition: all 0.35s ease-out;
}
.suffix-icon {
  color: $_form_control_color;
  font-size: 22px;
  width: 22px!important;
  height: 22px!important;
  margin-top: 6px;
  margin-left: -30px;
}
.icon-btn {
  color: #4EA8B0;
  font-size: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  transition: all 0.35s ease-out;
}

.icon-btn:hover {
  color: #8BE8DF;
  transition: all 0.35s ease-out;
}

.icon-btn-active {
  color: #ffffff;
  transition: all 0.35s ease-out;
}

.icon-btn-red {
  color: rgba(254, 113, 76, 0.6);
  font-size: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  transition: all 0.35s ease-out;
}

.icon-btn-red:hover {
  color: rgba(254, 113, 76, 1);
  transition: all 0.35s ease-out;
}

.icon-btn-mini-red {
  color: rgba(254, 113, 76, 0.6);
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  transition: all 0.35s ease-out;
}

.icon-btn-mini-red:hover {
  color: rgba(254, 113, 76, 1);
  transition: all 0.35s ease-out;
}

.icon-btn-light-no-hover {
  color: rgba(78, 168, 178, 0.8);
  font-size: 18px;
  -webkit-user-select: none;
  transition: all 0.35s ease-out;
}

.icon-btn-light {
  color: #1E4245;
  font-size: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  transition: all 0.35s ease-out;
}

.icon-btn-light:hover {
  color: #8BE8DF;
  transition: all 0.35s ease-out;
}

.icon-btn-big {
  color: #4EA8B0;
  cursor: pointer;
  -webkit-user-select: none;
}

.icon-mini-btn {
  color: #4EA8B0;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  transition: all 0.35s ease-out;
}

.icon-mini-btn:hover {
  color: #8BE8DF;
  transition: all 0.35s ease-out;
}

.icon-btn-logout {
  height: 30px;
  width: 30px;
  cursor: pointer;
  user-select: none;
  // color: rgb(254,113,76,0.4);
  color: #fe704cbb;
  margin-right: 9px !important;
  background-color: rgb(0, 0, 0, 0.2);
  border: 1px solid rgb(0, 0, 0, 0.2);
  transition: all 0.35s ease-out;

  .icn {
    height: 24px;
    width: 24px;
    margin-top: 2px;
    margin-left: 2px;
  }
}

.icon-btn-logout:hover {
  // color: rgb(254,113,76,0.9);
  color: #FE714C;
  // color: #000;
  // background-color: rgb(254,113,76,0.6);
  border-color: #FE714C;
  border-radius: 2px;
  transition: all 0.35s ease-out;
}

.circular {
  border-radius: 50% !important;
}

.uiToggleBtn {
  cursor: pointer;
  font-size: 12px;
  padding: 6px 18px;
  border-radius: 1px;
  border: 1px solid #FE714C;
  text-align: center;
  text-transform: uppercase;
  color: rgb(254, 113, 76);
  background-color: rgb(0, 0, 0, 0.2);
  transition: all 0.35s ease-out;
}

.uiToggleBtn:hover {
  color: #000;
  background-color: rgb(254, 113, 76, 0.6);
  transition: all 0.35s ease-out;
}

.uiToggleIconBtn {
  cursor: pointer;
  font-size: 12px;
  padding: 0px 10px;
  border-radius: 1px;
  border: 1px solid #FE714C;
  text-align: center;
  text-transform: uppercase;
  color: #FE714C;
  background-color: rgb(0, 0, 0, 0.2);
  transition: all 0.35s ease-out;

  .icon-btn-big {
    margin-top: 3px;
    color: #FE714C !important;
    transition: all 0.35s ease-out;
  }
}

.uiToggleIconBtn:hover {
  color: #000;
  background-color: rgb(254, 113, 76, 0.6);
  transition: all 0.35s ease-out;

  .icon-btn-big {
    color: #000 !important;
    transition: all 0.35s ease-out;
  }
}

.uiToggleBtnUnActive {
  color: #FE714C;
  background-color: rgb(0, 0, 0, 0.2);

  transition: all 0.35s ease-out;
}

.uiToggleBtnActive {
  color: #000;
  // background-color: #FE714C;
  background-color: rgb(254, 113, 76, 0.6);
  transition: all 0.35s ease-out;
}

.uiToggleBtnDisabled {
  -webkit-user-select: none;
  pointer-events: none;
  color: rgb(254, 113, 76, 0.4);
  border-color: rgb(254, 113, 76, 0.4);
  transition: all 0.35s ease-out;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  color: $_placeholdercolor;
  font-style: italic;
  text-transform: uppercase;
}

input::-moz-placeholder {
  color: $_placeholdercolor;
  font-style: italic;
  text-transform: uppercase;
}

input:-ms-input-placeholder {
  color: $_placeholdercolor;
  font-style: italic;
  text-transform: uppercase;
}

input:-moz-placeholder {
  color: $_placeholdercolor;
  font-style: italic;
  text-transform: uppercase;
}

textarea::-webkit-input-placeholder {
  color: $_placeholdercolor;
  font-style: italic;
  text-transform: uppercase;
}

textarea::-moz-placeholder {
  color: $_placeholdercolor;
  font-style: italic;
  text-transform: uppercase;
}

textarea:-ms-input-placeholder {
  color: $_placeholdercolor;
  font-style: italic;
  text-transform: uppercase;
}

textarea:-moz-placeholder {
  color: $_placeholdercolor;
  font-style: italic;
  text-transform: uppercase;
}

tr.element-row {}

tr.element-row:not(.expanded-row):hover {
  background: rgba(84, 163, 170, 0.25);
  transition: all 0.35s ease-out;
}

tr.element-row:not(.expanded-row):active {
  background: rgba(84, 163, 170, 0.25);
  transition: all 0.35s ease-out;
}

.dashboard-list-box {
  cursor: pointer;
  background-color: rgba(216, 216, 216, 0);
  transition: background-color 0.35s ease-in-out;
}

.dashboard-list-box:hover {
  background-color: rgba(216, 216, 216, 0.1);
  transition: background-color 0.35s ease-in-out;
}

.dashboard-list-box-no-hover {
  background-color: rgba(216, 216, 216, 0);
  transition: background-color 0.35s ease-in-out;
}

.dashboard-list-box_active {
  background-color: rgba(216, 216, 216, 0.16);
  transition: background-color 0.35s ease-in-out;
}

.list-box-trenner-row {
  height: 3px !important;
  background-image: url('/assets/trenner-light.svg');

}

.list-box-trenner-col {
  height: 3px;
}

.list-box-bg-rect-mini {
  width: 3px;
  height: 3px;
  background-color: #3F5455;
}




.dashboard-card {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 10px;
  height: 422px !important;
  width: 478px !important;
  overflow: hidden;
}

.dashboard-card-big {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 10px;
  height: 422px !important;
  width: 838px !important;
  overflow: hidden;
}

.dashboard-main-card-background {
  background-color: rgba(0, 0, 0, 1) !important;
  -webkit-box-shadow: 0 15px 35px rgba(12, 33, 50, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;
  box-shadow: 0 15px 35px rgba(12, 33, 50, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;

}

.exerTitle {
  // color: rgb(138,173,192,1)!important;
  font-size: 22px !important;
  // line-height: 22px;
  font-weight: 400;
  color: #4EA8B0;
  background-color: #1E2A33;
  text-transform: uppercase;
  // font-size: 16px;
  // font-weight: 600;
  // border-left: 1px solid rgb(15, 19, 20);
  // height: 100%;
  height: 30px !important;
  align-content: center;
  padding-left: 5px !important;
  padding-right: 5px !important;
  user-select: none;
  cursor: pointer;
}

h2 {
  // color: rgb(138,173,192,1)!important;
  font-size: 30px !important;
  margin-bottom: 0px;
}

h3 {
  color: rgb(138, 173, 192, 1) !important;
  font-size: 22px !important;
}

h4 {
  color: rgb(138, 173, 192, 1) !important;
  font-size: 18px !important;
}

h5 {
  color: rgb(138, 173, 192, 1) !important;
  font-size: 16px !important;
  margin-bottom: 0px;
  padding-top: 10px;
}

.mainExerciseDescription {
  color: rgb(138, 173, 192, 1);
  font-size: 12px;
  font-weight: 200;
  padding-bottom: 5px;
}

.truncate-overflow {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

}


.dashboard-box-inner-scrollable {
  height: 100%;
  overflow-y: scroll;
}

.truncate-overflow-single-line {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.truncate-overflow-double-line {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate-overflow-triple-line {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.descr {
  //background-color: #1E2A33;//rgba(66,80,80,.8);
  // padding-left: 2px;
  // padding-right: 2px;
  padding: 2px 6px;
  //color: #54A3AA;
  // color:#4EA8B0;
  color: rgba(255, 255, 255, .8);
  font-size: 12px;
  font-weight: 400 !important;
}

.itemTitle {
  background-color: $_dark_menu_item_background;
  /*   padding-top: 3px!important; */
  /* padding-bottom: 3px!important; */
  // padding-left: 8px!important;
  // padding-right: 8px!important;
  padding: 6px 10px !important;
  // color:#4EA8B0;
  color: $_font-main-darker //  color: rgba(78,168,178,0.56);
}

.bgDark {
  background-color: #1E2A33;
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
}

.bgWhite {
  color: rgba($color: $_font-dark, $alpha: 0.8)!important;
  background-color: #ffffff !important;
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
}

.title-copy {
  font-size: 14px;
  // padding-left: 8px!important;
  // padding-right: 8px!important;
  // color:#4EA8B0;
  color: rgba(255, 255, 255, .8);
  // background-color: rgba(66,80,80,.8);
  // background-color: #1E2A33;
}

.descr {
  //background-color: #1E2A33;//rgba(66,80,80,.8);
  // padding-left: 2px;
  // padding-right: 2px;
  padding: 2px 6px;
  //color: #54A3AA;
  // color:#4EA8B0;
  color: rgba(255, 255, 255, .8);

  font-weight: 400 !important;
}

.bb {
  border-bottom: 1px solid rgba(66, 80, 80, .6);
}

// .mat-caption-12 {
//   color: #4EA8B0;
//   font-size: 12px !important;
// }

// .mat-caption-12-bg {
//   padding: 2px 6px;
//   background-color: #1E2A33;
//   color: #4EA8B0;
//   font-size: 12px !important;
// }

// .fs16 {
//   font-size: 16px !important;
// }

// .fs18 {
//   font-size: 18px !important;

// }

// .fs20 {
//   font-size: 20px;
// }


// .cursorHelp {
//   cursor: help;
// }

// .tlc {
//   text-align: center !important;
// }

// .tlr {
//   text-align: right !important;
//   ;
// }

.uc {
  text-transform: uppercase;
}

.fs14 {
  font-size: 14px;
}

.fs12 {
  font-size: 12px;
}

.fs10 {
  font-size: 10px;
  line-height: 10px;
}

.miniToggleButton {
  cursor: pointer;
  -webkit-user-select: none;
  background-color: #1E2A33;
  color: #4EA8B0;
  transition: all 550ms cubic-bezier(0, 0, 0.2, 1);
}

.miniToggleButton_active {

  // cursor: auto;
  background-color: #4EA8B0;
  color: #1E2A33;
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
}

.miniToggleButton_unactive {


  background-color: #1E2A33;
  color: #4EA8B0;
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
}

.miniToggleButton:hover {
  background-color: #4EA8B0;
  color: #1E2A33;
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
}

.w24 {
  width: 24px;
}

.w40 {
  width: 40px;
}

.w50p {
  width: 50%;
}

.w70 {
  width: 70px;
}

.w90 {
  width: 90px;
}

.w180p {
  width: 180px !important;
}

.font-main {
  color: $_font-main;
  // color: #8BE8DF;
}

.font-main-darker {
  color: $_font-main-darker;
  // color: #4EA8B0;
}

.font-darker {
  // color: #4EA8B0;
  // color: rgba(78,168,178,0.56);
  color: $_font-darker;
}

.tt-box-sm {
  &.dark {
    color: #4EA8B0;
    background-color: #1E2A33;
    border-left: 1px solid rgb(15, 19, 20);
  }

  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;

  // height: 100%;
  height: 30px !important;
  align-content: center;
  white-space: nowrap;
  // margin-left: 1px;
}
.tt-box-sm:not(.dark) {
  // color: #29575b;
  color: $_font-dark!important;
  background-color: #ffffff;
  border-left: 1px solid rgba($color: $_bg_light_50, $alpha: 0.7);
}


.tt-box-sm-inner {
  width: 26px;
  height: 26px;
  border: 2px solid rgba($color: #8BE8DF, $alpha: 0.5)
}

.tt-border {
  &.dark {

    border: 1px solid rgba($color: #8BE8DF, $alpha: 0.2)
  }
}

.tt-border:not(.dark) {
  border: 1px solid rgba($color: #ffffff, $alpha: 1)
}

.font-bg {
  padding: 4px !important;
  background-color: #1E2A33;
  color: rgba(78, 168, 178, 0.56);
}

.font-dark {
  color: #1E2A33
}

.b {
  font-weight: 500 !important;
}

.font-bg-2 {
  padding: 4px !important;
  background-color: #1E2A33;
  color: #8BE8DF;

}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.sectionGoaeTT {
  white-space: pre-line;
}

.userGroupContainer {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 350px;
  min-height: 120px;
}

.userGroupContainer2 {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* width: 450px; */
  width: 33.3333%;
  // min-height: 120px;
  height: 350px;
  overflow: hidden;
}

// .inject_no{
// color:#48a8b2
// }
.txtSmW {
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
}

.txtSmWM {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.txtSmWC {
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
}

.errorColor {
  color: #ff0000;
}

.cdk-overlay-pane {
  max-width: 99.5vw !important;
}

.admin-list-box {
  cursor: pointer;

  margin: 2px !important;
  color: rgba(78, 168, 176, 0.7);
  background-color: rgba(216, 216, 216, 0);
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
}

.admin-list-box:hover {

  color: rgba(255, 255, 255, 1);
  background-color: rgba(78, 168, 176, 1);
  // background-color: rgba(216,216,216,0.1);
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
}

.admin-list-box_active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(78, 168, 176, 1);
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
}

.sideNavRoleItem {
  width: 100%;
  height: 120px;
  color: $_font-main;
  // background-color: #2A2E36;
  position: relative;
  // height: 59px;
  margin-top: 1px;
  /* box-shadow: 2px 0px 4px rgba($color: #000000, $alpha: 0.15); */
  // margin-bottom: 1px!important;
  /*   background-color: rgba(0,0,0,.2); */
  // border-bottom: 1px solid  rgba($color: #000000, $alpha: 1);
  // border-right: 1px solid  rgba($color: #000000, $alpha: 1);
  -webkit-user-select: none !important;
}

.sideNavRoleItemlight {
  color: $_iconBtnDark;
  // background-color: $_bg_light_50;
  background-color: rgba($color: $_bg_light_50, $alpha: 0.35);
}

.adminBtn {
  position: absolute;
  z-index: 1021;
  top: 0;
  right: 0;
}

.rootV {
  // position: absolute;
  // width: 100%;
  // height: 100%;
  // left: 0px;
  // top: 0px;
  // overflow: hidden;
}

.w33p {
  width: 33%;
}

.w30p {
  width: 30%;
}

.w30 {
  width: 30px;
}

.w38 {
  width: 38px;
}

.w40 {
  width: 40px;
}

.w40p {
  width: 40%;
}

.w50 {
  width: 50px;
}

.w60 {
  width: 60px;
}

.w60p {
  width: 60%;
}

.w70p {
  width: 70%;
}

.w120 {
  width: 120px;
}
.w150 {
  width: 150px;
}
.w160 {
  width: 160px;
}

.w180 {
  width: 180px;
}

.w230 {
  width: 230px;
}

.w250 {
  width: 250px;
}

.w260 {
  width: 260px;
}

.w300 {
  width: 300px;
}

.usergroup-scroller::-webkit-scrollbar {
  /*  width: 14px; */
  width: 3px !important;
  background-color: #2A2E36 !important;
}

.usergroup-scroller::-webkit-scrollbar-thumb {
  /*  width: 14px; */
  width: 3px !important;
  background-color: #4EA8B0 !important;
}

// ::-webkit-scrollbar {
//   /*  width: 14px; */
//   width:10px!important;
//   background-color: #2A2E36!important;
//  }

//  /* Track */
//  ::-webkit-scrollbar-track {
//    border-radius: 8px;
//  /*   background-color: rgba(12,20,22, 0.4); */
//    transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
//  }

//  /* Handle */
//  ::-webkit-scrollbar-thumb {
//   /*  background-color: rgba(56, 232, 223, 0.2); */
//   border-radius: 8px;
//   cursor: move;


//   background-color: #898C91!important;
//   opacity: 0.5;
//    transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
//  }

//  /* Handle on hover */
//  ::-webkit-scrollbar-thumb:hover {
//    border-radius: 8px;

//    background-color: #898C91!important;
//    opacity: 1;
//     transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);

//  }

.invite-overlay {
  position: relative;
}

.invite-toolbar {
  height: 66px;
}

.txtLightblue {
  color: #A0FFEE !important;
}

.w20p {
  width: 20%;
}

.w60p {
  width: 60%;
}

.w33p {
  width: 33.3333333%;
}

.locations-card-mod {
  position: absolute;
  z-index: 999999999999998;
  right: 0px;
  /* bottom: 0px; */
  width: 478px;
  height: 422px;
  top: 100vh;
  margin-top: -422px;
}

// .mat-mdc-dialog-container .mdc-dialog__surface {
//   background-color: $_bg_dark_80 !important;
// }

:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-end // , .mat-form-field-appearance-outline

// .mat-form-field-outline-start
  {

  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  //  border: 1px solid red;
  border-radius: 0px !important;
}

.select-form-field {
  /* color: #DFE6BA!important; */
  font-size: 14px !important;
  // font-weight: 500!important;
  line-height: 0.9 !important;
  padding: 0px !important;
  margin: 0px !important;
}

.time-modify {
  height: 18px;
  width: 18px;
  display: flex;
  text-align: center;
  color: rgb(138, 173, 192, 0.5);
  transition: all 450ms cubic-bezier(0, 0, 0.2, 1);
}

.time-modify:hover {
  height: 18px;
  width: 18px;
  display: flex;
  text-align: center;
  color: rgb(138, 173, 192, 1);
  transition: all 450ms cubic-bezier(0, 0, 0.2, 1);
}

.inject-dateTime {
  border-radius: 2px;
  font-size: 16px;
  padding: 2px 6px !important;
  text-align: center;
  border: 1px solid rgba(56, 232, 223, 0.2);
  color: rgb(138, 173, 192, 1);
  transition: all .25s ease-in-out;
}

.tt_selection {
  cursor: pointer;
  user-select: none;
  width: 33px;
}

.injectDateTimeActive {

  border: 1px solid #FE714C;
  transition: all .25s ease-in-out;
}

// .dxfx_icon_btn_medium{
//   // transform: rotateZ(0deg);
//   width: 22px!important;
//   height: 22px!important;
//   font-size: 22px!important;
//   color:#ABBBC7!important;
//   transition: color 0.35s ease-in-out;
//   cursor: pointer;
// }

// .dxfx_icon_btn_medium:hover{
//   // transform: rotateZ(270deg);
//   color:#1976D2!important;
//   transition: color 0.35s ease-in-out;
// }



.filterCheckBox {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.state_DRAFT_in {
  border: 1px solid rgba(82, 86, 90, 0);
  background-color: rgba(82, 86, 90, 0.9);
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_REQUESTED_in {
  background-color: rgba(78, 168, 176, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_CANCELED_in {
  background-color: rgba(36, 79, 101, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_PLANNED_in {
  background-color: rgba(138, 172, 192, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_PREPARED_in {
  background-color: rgba(170, 175, 141, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_RUN_in {
  background-color: rgba(203, 73, 32, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_DELIVERED_in {
  background-color: rgba(47, 70, 84, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_out {
  //border: 2px solid rgba(82, 86, 90 ,0.9)!important;
  background-color: rgba(82, 86, 90, 0.0) !important;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_in {
  // border: 1px solid rgba(82, 86, 90 ,0);
  //border: 2px solid rgba(82, 86, 90 ,0.9)!important;
  background-color: rgba(82, 86, 90, 0.9);
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_DRAFT_out {
  border: 1px solid rgba(82, 86, 90, 0.9);
  background-color: rgba(82, 86, 90, 0.0);
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_REQUESTED_out {
  background-color: rgba(78, 168, 176, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_CANCELED_out {
  background-color: rgba(36, 79, 101, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_PLANNED_out {
  background-color: rgba(138, 172, 192, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_PREPARED_out {
  background-color: rgba(170, 175, 141, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_RUN_out {
  background-color: rgba(203, 73, 32, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.state_DELIVERED_out {
  background-color: rgba(47, 70, 84, 0.9);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.dxfx_icon_btn_mini {
  width: 18px !important;
  // height: 18px!important;
  font-size: 16px !important;
  color: #ABBBC7 !important;
  transition: color 0.35s ease-in-out;
  cursor: pointer;
}

.dxfx_icon_btn_mini:hover {
  color: #ffffff !important;
  transition: color 0.35s ease-in-out;
}
.dxfx_icon_btn_mini_role_item {
  width: 18px !important;
  font-size: 16px !important;
  // color: #ABBBC7 !important;
  color: $_font-main!important;
  // background-color: #2A2E36;
  opacity: 0.7;
  transition: all 0.35s ease-in-out;

  cursor: pointer;
}

.dxfx_icon_btn_mini_role_item:hover {
  // color: #ffffff !important;
  opacity: 1;
  transition: all 0.35s ease-in-out;
}

.dxfx_icon_btn_mini_circ {
  width: 26px !important;
  height: 26px !important;
  border: 1px solid $_container_border;
  border-radius: 50%;
  padding-top: 3px;
  text-align: center;
  font-size: 18px !important;
  color: #ABBBC7 !important;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
}
.dxfx_icon_btn_mini_circ_error {
  width: 26px !important;
  height: 26px !important;
  border: 1px solid $_warn;
  background-color: rgba($color: $_container_border_warn, $alpha: 0.1)!important;
  border-radius: 50%;
  padding-top: 3px;
  text-align: center;
  font-size: 18px !important;
  color: $_iconBtnDarkActive;
  // color: #ABBBC7 !important;
  pointer-events: none;
}
.dxfx_icon_btn_mini_circ:hover {
  color: #ffffff !important;
  border-color: $_dark_menu_item_background_hover_light;
  transition: all 0.35s ease-in-out;
}

.dxfx_icon_btn_mini_WARN {
  width: 18px !important;
  height: 18px !important;
  font-size: 16px !important;
  font-weight: bold;
  // color: #b16767 !important;
  color: rgba($color: $_warn, $alpha: 1)!important;
  // opacity: 0.6;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
}

.dxfx_icon_btn_mini_WARN:hover {
  // color: #D32F2F !important;
  color: rgba($color: $_warn, $alpha: 0.7)!important;
  // opacity: 1;
  transition: all 0.35s ease-in-out;
}

.dxfx_icon_btn_medium_WARN {
  // transform: rotateZ(0deg);
  width: 22px !important;
  height: 22px !important;
  font-size: 22px !important;
  color: #b16767 !important;
  opacity: 0.6;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
}

.dxfx_icon_btn_medium_WARN:hover {
  color: #D32F2F !important;
  opacity: 1;
  transition: all 0.35s ease-in-out;
}


.dxfx_icon_btn_medium {
  width: 22px !important;
  height: 22px !important;
  font-size: 22px !important;
  // color:#ABBBC7!important;
  color: $_font-main-darker;
  // color: #ffffff;
  // color:#ABBBC7!important;
  opacity: 1;
  margin-right: 4px;
  transition: all 0.35s ease-in-out;
  cursor: pointer;
}

.dxfx_icon_btn_medium:hover {
  // transform: rotateZ(270deg);
  // color:#ffffff!important;
  // color: $_iconBtnLightActive;
  opacity: 0.5;
  transition: all 0.35s ease-in-out;
}

.dxfx_icon_red {
  width: 22px !important;
  height: 22px !important;
  font-size: 22px !important;
  color: $_iconBtnLight;



}

.dark {
  color: $_iconBtnDark;
}

.backdrop-background {
  // filter: blur(10px)!important;
  background-color: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(15px);
}

.backdrop-background-light {
  // filter: blur(10px)!important;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.fs_backdrop {

  // filter: blur(10px)!important;
  /*   background-color: rgba(63,168,175,0.21); */
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(2px);
  /*   -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important; */

}

.adminPanelClass {
  .mat-dialog-container {
    background-color: #0c1416;
    background: #0c1416;
    background: radial-gradient(circle, #0c1416 0%, #070d0d 100%);
    // background-color: rgba(63,168,175,1)!important;
    // background: rgba(63,168,175,1)!important;
    // backdrop-filter: blur(8px);
    /*  background-color: rgba(0,0,0,0.36)!important; */
    /*   background: rgba(0,0,0,0.26)!important; */
  }
}

.fsPanelClass {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: $_bg_dark_80 !important;
  }
  // max-width: 1400px!important;
  .mat-dialog-container {
    // max-width: 1200px!important;
    // background-color: rgba(63, 168, 175, 0.12) !important;
    background: rgba(63, 168, 175, 0.12) !important;
    background-color: $_bg_dark_80 !important;
    backdrop-filter: blur(8px);
    /*  background-color: rgba(0,0,0,0.36)!important; */
    /*   background: rgba(0,0,0,0.26)!important; */
  }
}

.fsPanelClassLight {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: $_bg_light_50 !important;
  }

  .mat-dialog-container {

    backdrop-filter: blur(8px);

  }
}

.table-dark {
  // background-color: rgba(0,0,0,.2);
  background: rgba(18, 18, 23, 0);
}

th {
  // background: rgba(18,18,23,1);
  font-size: 12px !important;
  font-weight: 200 !important;
  color: #a2a686 !important;

}

td {
  // border-color: hsla(0,0%,100%,.12)!important;
  // color: #54a3aa !important;
  color: #54a3aa !important;
  font-size: 14px;
  font-weight: 400;
  background: rgb(22, 23, 27);
}

.td {
  // border-color: hsla(0,0%,100%,.12)!important;
  // color: #54a3aa !important;
  color: #54a3aa !important;
  font-size: 14px;
  font-weight: 400;
  background: rgb(22, 23, 27);
}

.tr {
  // border-color: hsla(0,0%,100%,.12)!important;
  // color: #54a3aa !important;
  color: #54a3aa !important;
  font-size: 13px;
  font-weight: 300;
  background: rgb(22, 23, 27);
  border-bottom: 1px solid $_container_border;
}
.tbrRight {
  border-right: 1px solid $_container_border;
}
tbody>tr {
  cursor: pointer;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: none !important;
  background: none !important;
  // background: #D32F2F!important;
}

.customSnackWarn {
  background-color: #D32F2F !important;
  background: #D32F2F !important;
  color: white;
  font-size: 22px;
}

.customSnack {
  background-color: #D32F2F !important;
  background: #D32F2F !important;
  color: white;
  font-size: 18px;

}

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: rgba(233, 229, 229, 0.804) !important;
  background-color: transparent;
}

.mat-mdc-checkbox label {
  color: #eff5f5 !important;
  margin-top: 8px;
}

.captionColor {
  color: rgb(138, 173, 192, 0.5);
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

// .overlay-toolbar{
//   position: static!important;
//   height: 44px!important;
//   width: 100%;
//   border-radius: 6px;
//   background-color: rgb(0,0,0,0.2)!important;
// }
.overlay-toolbar {
  position: static !important;
  height: 44px !important;
  width: 100%;
  overflow: hidden;
  // border-radius: 6px;
  background: $_bg_dark_80 !important;
  // background: rgba(18,18,23,1)!important;
}

._br {
  border: 1px solid deeppink !important;
}

._brGreen {
  border: 1px solid greenyellow !important;
}

._br_light {
  border: 1px solid rgba($color: deeppink, $alpha: 0.2) !important;
}

.strokedText {
  text-decoration: line-through !important;
  font-style: italic;
  color: rgba(233, 229, 229, 0.8) !important;
}


.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px !important;
  font-size: 16px !important;
  // border: 1px solid #AABBC7;
  color: #9c9595;
  // border-color: rgb(0,0,0,0.97)!important;
  // background-color: #121217;
  transition: all 0.25s ease-in-out;
}


// $--mat-standard-button-toggle-background-color : red!important;

.mat-button-toggle {
  background-color: #121217 !important;
  // background: #655050 !important;
  border-color: rgb(0, 0, 0, 0.97) !important;
  // background-color: red;
  // button {
  //     color: $_yellow;
  // }
}

// mat-form-field.mat-form-field.mat-white {
//   &.mat-focused {
//     .mat-form-field-label {
//         color: #FAFAFA;
//     }
//     .mat-form-field-ripple {
//         background-color: #FAFAFA !important;
//     }
//   }
//   .mat-form-field-label,.mat-form-field-label.mat-focused,
//   .mat-form-field.mat-focused.matform-field-should-float {
//       color: #FAFAFA;
//   }
//   .mat-form-field-underline, .mat-form-field-underline.mat-focused {
//       background-color: #FAFAFA;
//     }
//   }
.mat-button-toggle-group {
  // background-color: #655050 !important;
  // background: #655050 !important;
  border: none !important;
  // border-color: rgb(0,0,0,0.97)!important;
}

.mat-button-toggle-checked .mat-button-toggle-label-content {
  //color: var(--mat-standard-button-toggle-selected-state-text-color);
  color: rgba(78, 168, 178, 1) !important;
  // border-color: $_yellow;
  // background-color: #121217;
  // border-color: rgb(0,0,0,0.97)!important;
  // background: #121217;
  transition: all 0.25s ease-in-out;
  // background-color: var(--mat-standard-button-toggle-selected-state-background-color);
}

.mat-button-toggle-checked {
  border: 1px solid rgba(78, 168, 178, 1) !important;
  border-radius: 4px;
  color: #9c9595;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(78, 168, 178, 0.5) !important;
}

.borderR {
  border-radius: 2px;
  border: 1px solid rgba(78, 168, 178, 1) !important;
}

.mat-mdc-form-field-infix {
  min-height: 38px !important;
  color: rgba(78, 168, 178, 1) !important;
}

.mdc-text-field--outlined {
  // height: 48px!important;
  color: rgba(78, 168, 178, 0.5) !important;
  overflow: visible;
  align-content: center;
  border-width: 1px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  align-content: center;
  /* padding-bottom: 16px; */

}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px !important;
}

.mat-mdc-select-panel {
  // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  // width: 100%;
  // max-height: 275px;
  // outline: 0;
  // overflow: auto;
  // padding: 8px 0;
  // border-radius: 4px;
  // box-sizing: border-box;
  // position: static;
  background-color: $_dark_menu_item_background !important;
  /*   padding-top: 3px!important; */
  /* padding-bottom: 3px!important; */
  // padding-left: 8px!important;
  // padding-right: 8px!important;

  //  color: rgba(78,168,178,0.56)!important;
  //background-color: rgb(0,0,0,0.97)!important;
}

.mat-mdc-option {
  color: $_tf_input !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  background-color: $_dark_menu_item_background_hover !important;
  margin-bottom: 1px !important;
  border-bottom: 1px solid $_tableBorder;
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
  // display: flex;
  // position: relative;
  // align-items: center;
  // justify-content: flex-start;
  // overflow: hidden;
  // padding: 0;
  // padding-left: 16px;
  // padding-right: 16px;
  // -webkit-user-select: none;
  // user-select: none;
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: antialiased;
  // cursor: pointer;
  // -webkit-tap-highlight-color: rgba(0,0,0,0);
  // color: var(--mat-option-label-text-color);
  // font-family: var(--mat-option-label-text-font);
  // line-height: var(--mat-option-label-text-line-height);
  // font-size: var(--mat-option-label-text-size);
  // letter-spacing: var(--mat-option-label-text-tracking);
  // font-weight: var(--mat-option-label-text-weight);
  min-height: 32px !important;
}

.mat-mdc-option:hover {
  color: $_font-dark !important;
  background-color: $_dark_menu_item_background_hover_light !important;
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: $_font-main !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: $_font-main !important;
}

.mat-mdc-select-arrow {
  color: rgba(78, 168, 178, 1) !important;
}

// .mat-caption {
//   font: 300 10px / 12px Montserrat !important;
//   color: rgb(138, 173, 192, 0.7) !important;
//   // font-weight: 100;
// }

.uc {
  text-transform: uppercase;
}

// .mat-mdc-tooltip {
//   background-color:rgba(143, 43, 43, 0.9)!important;
//   color: #8BE8DF!important;
//   font-size: 14px!important;
// }

// .navigationTT {
//   background-color:rgba(143, 43, 43, 0.9)!important;
//   // background:rgba(143, 43, 43, 0.9)!important;
//   // border: 1px solid  rgba(21,21,21,0)!important;
//   // border-radius: 4px!important;
//   // color: #8BE8DF!important;

//   // font-size: 14px!important;
//   // font-weight: 300!important;

//   padding: 4px 10px!important;
//   // -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
//   // box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
//   //white-space: pre-line;
// }
.mapLabelTT {
  background-color: rgba(161, 255, 239, 0.7) !important;
  border-radius: 4px;
  color: $_font-dark;
  // background:rgba(143, 43, 43, 0.9)!important;
  // border: 1px solid  rgba(21,21,21,0)!important;
  // border-radius: 4px!important;
  // color: #8BE8DF!important;

  // font-size: 14px!important;
  // font-weight: 300!important;

  padding: 4px 10px !important;
  margin-top: -40px;
  // -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  // box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  //white-space: pre-line;
}

// .mainMenuTT{
//   background: rgba(25,118,210,0.9);
//   font-size: 14px!important;//$mat-tooltip-font-size;
//   font-weight: 500!important;
//   color: #FFF!important;
// }
// .employeeInfoTT{
//   background:rgba(0,0,0,0.9);
//   border: 1px solid  rgba(21,21,21,0.3)!important;
//   font-size: 14px!important;//$mat-tooltip-font-size;
//   font-weight: 300!important;
//   color: rgba(255,255,255,0.7)!important;

// }
mat-icon {
  height: auto !important;
}

.bgDarg {
  background-color: $_bg_dark_100 !important;
}

.bgDarg_mid {
  background-color: $_bg_dark_80 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  // border: none;
  color: $_tf_input !important;
  -webkit-text-fill-color: $_tf_input;
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 1) inset;
}

.h18 {
  height: 18px;

}

.h30 {
  height: 30px !important;
}

.h35 {
  height: 35px !important;
}

.h36 {
  height: 36px;
}

.h38 {
  height: 38px !important;
}

.h40 {
  height: 40px !important;
}

.mat-mdc-menu-panel {
  --mat-menu-container-shape: 4px;
  min-width: 70px !important;
  // min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  outline: 0;
  /* border-radius: var(--mat-menu-container-shape); */
  background-color: $_bg_dark_100 !important;
  // background-color: rgb(23, 24, 26) !important;
  will-change: transform, opacity;
}

.mat-mdc-menu-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-user-select: none;
  user-select: none;

  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  width: 100%;
  text-align: left;
  box-sizing: border-box;
  // color: inherit;
  // font-size: inherit;
  // background: none;
  // text-decoration: none;
  font-size: 14px !important;
  margin: 0;
  align-items: center;
  // min-height: 48px;
  min-height: 36px !important;
}

.mat-mdc-menu-item,
.mat-mdc-menu-item:visited,
.mat-mdc-menu-item:link {
  // color: #fff!important;
  color: $_tf_input_main !important;
  background-color: $_bg_dark_60 !important;

  mat-icon {
    // font-size: 16px!important;
    // color: #fff!important;
    color: $_tf_input_main !important;
    // transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
  }

  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
}

.mat-mdc-menu-item:hover {
  //color: $_tf_input_main!important;
  color: #fff !important;
  // background-color: $_bg_dark_100!important;
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);

  mat-icon {
    //color: $_tf_input_main!important;
    color: #fff !important;
    // transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
  }
}

.mat-mdc-menu-content {
  padding: 0px !important;
  font-size: 12px!important;
}
.mat-mdc-menu-content* {
  padding: 0px !important;
  font-size: 12px!important;
}
.mat-mdc-menu-item {

  mat-icon {
    font-size: 16px!important;
    width: 18px!important;
  }
  .mat-mdc-menu-item-text {
    font-size: 14px!important;
  }
}
.e-toolbar {
  background: #1E2A33 !important;
  border: 1px solid $_tableBorder //rgba(138, 173, 192, 0.85)!important;
}

.e-toolbar .e-toolbar-items {
  background: #1E2A33 !important;
}

.e-toolbar .e-toolbar-item.e-overlay {
  background: #1E2A33 !important;
  opacity: 0.5;
  color: $_font-main
}

.e-richtexteditor .e-rte-content,
.e-richtexteditor .e-source-content {
  background: #1E2A33 !important;
  color: $_font-main;
  font-size: 16px;
}

.e-rte-container {
  border: 1px solid $_tableBorder //rgba(138, 173, 192, 0.85)!important;
}

.e-toolbar-wrapper {
  border-bottom: 1px solid $_tableBorder //rgba(138, 173, 192,0.8)!important;
}



// .my-form-field .mat-form-field-wrapper {
//   margin-bottom: -1.25em;
// }
// .mat-form-field:not(.mat-form-field-has-label) .mat-form-field-infix {
//   border-top-width: 0;
// }

// .mat-form-field-wrapper {
//   padding-bottom: 0px;
//   margin-bottom: 0px;
//   margin-top: 0px;
// }

// .mat-form-field-label-wrapper {
//   padding-top: 0px;
// }
.mdc-text-field--outlined {
  --mdc-outlined-text-field-outline-width: 1px !important;
  --mdc-outlined-text-field-focus-outline-width: 1px !important;
  --mdc-outlined-text-field-container-shape: 4px;
}


.e-dropdown-popup {
  position: absolute;
  z-index: 99999999999999;
  background-color: #000;
}

// .ql-toolbar.ql-snow {
//   // border: 1px solid #ccc;
//   // box-sizing: border-box;
//   // font-family: 'Helvetica Neue','Helvetica','Arial',sans-serif;
//   // padding: 8px;
//   height: 46px!important;
// }

.gm-style {
  div {
    border: none !important;
  }
}

tr {
  height: 40px !important;

}

thead {
  background-color: $_bg_dark_60 !important;
}
.thead {
  background-color: $_bg_dark_60 !important;
}

.titleBarTxt {
  color: #4EA8B0;
  font-size: 16px;
  font-weight: 500;
  align-content: center;
  text-align: left;
  border: 1px solid;
  padding-left: 10px !important;
  border-color: rgba(78, 168, 178, 0.5) !important;
  border-radius: 2px;
}

.chatMessageDateHeader {
  font-size: 12px;
  // font-style: italic;
  color: $_font-main-darker;
}

.chatMessageDate {
  font-size: 12px;
  font-style: italic;
  color: $_font-darker;
}


.chatBubble {
  max-width: 303px;
  padding: 10px 10px 10px 10px !important;
  border-radius: 10px;
  background-color: rgba(25, 71, 68, 0.186);
  // color: #4EA8B0;
  font-size: 16px;
  letter-spacing: 0.03125em;
  line-height: 18px;
  // width: 200px;
  // display: block;
  line-break: anywhere;
  // font-style: italic;
  color: $_font-main-darker;
}

.chatBubbleIcon {
  width: 30px !important;
  height: 30px !important;
  // padding: 10px 10px 10px 10px!important;
  border-radius: 20px;
  background-color: rgba(139, 232, 223, 0.13);
  background-image: url(assets/face_26dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg);
  // background-image: url(assets/face_20dp_E8EAED_FILL0_wght400_GRAD0_opsz20.svg);
  background-repeat: no-repeat;
  background-position: center;
  // color: #4EA8B0;
  // font-size: 18px!important;
}


.boxRight {
  justify-content: end;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: rgba(135, 71, 52, 0) !important;
  // background-color:rgba(254, 119, 76, 0.7)!important;
  // color: #ffffff!important;
  // font-size: 14px!important;
  padding: 0px !important;
  border-radius: 0px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  min-height: 12px !important;
  // -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  // box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
}

.chatTT {
  // background: rgba(139, 232, 223, 1)!important;
  // background-color: rgba(139, 232, 223, 0.16)!important;
  background-color: #0c1416 !important;
  // border: 1px solid  rgba(209,56,53,0.3)!important;
  border-radius: 4px !important;
  color: #12eafd !important;
  // font-family: mat-font-family($config);
  font-size: 14px !important; //$mat-tooltip-font-size;
  font-weight: 300 !important;
  line-height: 16px !important;
  padding: 10px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  max-width: 500px !important;
  white-space: pre-line;
  // -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  //   box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
}

.navTT {
  // background: rgba(139, 232, 223, 1)!important;
  // background-color: rgba(139, 232, 223, 0.16)!important;
  // background-color: #3699b2 !important;
  background-color: #0c1416 !important;
  // border: 1px solid  rgba(209,56,53,0.3)!important;
  border-radius: 4px !important;
  color: #fff !important;
  // color: #2c3c3d !important;
  // font-family: mat-font-family($config);
  font-size: 14px !important; //$mat-tooltip-font-size;
  font-weight: 300 !important;
  line-height: 16px !important;
  padding: 10px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  max-width: 500px !important;
  white-space: pre-line;
  text-transform: uppercase;
  // color: red!important;
  // .mat-mdc-tooltip-show {
  //   color: green!important;
  // }
  // -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  //   box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
}
.baseTT {
  // background: rgba(139, 232, 223, 1)!important;
  // background-color: rgba(139, 232, 223, 0.16)!important;
  // background-color: #3699b2 !important;
  background-color: #0c1416 !important;

  border-radius: 4px !important;
  color: #fff !important;

  font-size: 14px !important; //$mat-tooltip-font-size;
  font-weight: 300 !important;
  line-height: 16px !important;
  padding: 10px !important;
  // -webkit-box-shadow: none !important;
  // box-shadow: none !important;
  max-width: 500px !important;
  white-space: pre-line;
}
.injectTimelineTT {
  // background: rgba(139, 232, 223, 1)!important;
  // background-color: rgba(139, 232, 223, 0.16)!important;
  // background-color: #3699b2 !important;
  background-color: #0c1416 !important;

  border-radius: 4px !important;
  color: #fff !important;

  font-size: 14px !important; //$mat-tooltip-font-size;
  font-weight: 300 !important;
  line-height: 16px !important;
  padding: 10px !important;
  // -webkit-box-shadow: none !important;
  // box-shadow: none !important;
  max-width: 500px !important;
  white-space: pre-line;
}

.errorTT {
  background-color: $_bg_warn !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 14px !important; //$mat-tooltip-font-size;
  font-weight: 300 !important;
  line-height: 16px !important;
  padding: 10px !important;
  max-width: 500px !important;
  white-space: pre-line;
}


.notification {
  position: absolute;
  left: 20px;
  top: 10px;
  border: 2px solid #842029;
  background-color: rgba($color: #2c0b0e, $alpha: 0.9);
  // box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
  background: linear-gradient(180deg, rgba($color: #2c0b0e, $alpha: 0.9) 0%, rgba($color: #842029, $alpha: 0.85) 100%);

  width: 360px;
  height: 76px;
  border-radius: 10px;
  z-index: 999999;
  opacity: 0;
  transform: translateX(-600px);

  .userName {
    font-size: 12px;
    letter-spacing: 0.03125em;
    line-height: 14px;
    font-weight: 300;
    // width: 200px;
    // display: block;
    line-break: anywhere;
    // font-style: italic;
    color: $_font-main;
  }

  .msg {
    font-size: 14px;
    letter-spacing: 0.03125em;
    line-height: 16px;
    // width: 200px;
    // display: block;
    line-break: anywhere;
    // font-style: italic;
    color: $_font-main;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.DISABLED {
  cursor: not-allowed;
  pointer-events: unset;
  opacity: 0.9;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.dev-banner {
  width: 110px;
  height: 109px;
  position: fixed;
  z-index: 99999999;
  top: 0px;
  left: 100vw;
  margin-top: -16px;
  margin-left: -100px;
  background-image: url(./assets/dev_banner.png);
  overflow: hidden;
  pointer-events: none;
}



.p0 {
  padding: 0px !important;
}

.p1 {
  padding: 1px !important;
}

.p5 {
  padding: 5px !important;
}

.m0 {
  margin: 0px !important;
}

.m1 {
  margin: 1px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml1 {
  margin-left: 1px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.mr1 {
  margin-right: 1px !important;
}

.mr3 {
  margin-right: 3px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb1 {
  margin-bottom: 1px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.m_b5 {
  margin-bottom: 5px !important;
}

.m_b10 {
  margin-bottom: 10px !important;
}

.m_b20 {
  margin-bottom: 20px !important;
}

.m_b40 {
  margin-bottom: 40px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pt1 {
  padding-top: 1px !important;
}

.pt2 {
  padding-top: 2px !important;
}

.pt3 {
  padding-top: 3px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt8 {
  padding-top: 8px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pt100 {
  padding-top: 100px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb16 {
  padding-bottom: 16px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb4 {
  padding-bottom: 4px !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.pb1 {
  padding-bottom: 1px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pb8 {
  padding-bottom: 8px !important;
}



.pl40 {
  padding-left: 40px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl16 {
  padding-left: 16px !important;
}

.pl15 {
  padding-left: 15px;
}

.pl14 {
  padding-left: 14px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl3 {
  padding-left: 3px !important;
}

.pl2 {
  padding-left: 2px !important;
}

.pl1 {
  padding-left: 1px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr14 {
  padding-right: 14px !important;
}

.pr12 {
  padding-right: 12px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr5 {
  padding-right: 6px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.pr3 {
  padding-right: 3px !important;
}



// .gmnoprint {
//   display: none !important;
// }

.locHeadline {
  font-size: 16px;
  color: $_font-dark !important;
}

.locDesc {
  font-size: 14px;
  color: $_font-dark !important;
}
.borderRight {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 40px;
  // padding-left: 10px!important;
  align-content: center;
}

.preloader_OL {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  pointer-events: none;
  // opacity: 0;
  // background-color: rgb(138, 173, 192, 0.9);
  background-color: rgba($color: $_bg_dark_80, $alpha: 0.0) !important;
  backdrop-filter: blur(0px);
  .innerLoader {
    opacity: 0;
    transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
  }
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
}
.preloader_OL_out {
  // opacity: 0;
  background-color: rgba($color: $_bg_dark_80, $alpha: 0.0) !important;
  backdrop-filter: blur(0px);
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
  .innerLoader {
    opacity: 0;
    transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
  }
}
.preloader_OL_in {
  // opacity: 1;
  background-color: rgba($color: $_bg_dark_80, $alpha: 0.95) !important;
  backdrop-filter: blur(5px);
  transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
  .innerLoader {
    opacity: 1;
    transition: all 0.35s cubic-bezier(0, 0, 0.2, 1);
  }
}
