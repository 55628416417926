$_red: #D40000;
$_orange: #FF761D;
$_yellow: #FEB715;
$_primary: #4EA8B0;
$_placeholdercolor: rgb(138, 173, 192, 0.8);
// $_placeholdercolor:#3F3F3F;


$_green: #3EC667;
$_green_txt: #32A555;
$_green_dark: #21823E;
$_blue: #1976D2;

$_warn: rgb(211, 47, 47);

$_inactive_button_text: #AABBC7;

$_entwurf: #BFCCD5;
$_versandbereit: #1976D2;
$_versendet: #3EC667;
$_teilzahlung: #A9F0BF;
$_bezahlt: #3EC667;
$_gemahnt: #FA4E12;

$_accent: #94C1CA;

$_bg_dark_100: rgb(15, 19, 20);
$_bg_dark_80: rgb(23, 24, 26);
$_bg_dark_60: rgb(31, 33, 37);
$_bg_warn: #D32F2F;

$_tableBorder: #454d55;

$_tf_input: rgb(138, 173, 192, 1);
// $_tf_input: #8BE8DF;
$_tf_input_main: rgb(138, 173, 192, 1);

$_iconBtnLight: #ABBBC7;
$_iconBtnLightActive: #ffffff;
$_iconBtnDark: #454d55;
$_iconBtnDarkActive: #FA4E12;

$_font-main: rgb(139, 232, 223);
$_font-main-darker: #4EA8B0;
$_font-darker: rgba(78, 168, 178, 0.56);
$_font-dark: rgb(6, 29, 31);

$_dark_menu_item_background: rgba(30, 42, 51, 1);

$_dark_menu_item_background_hover_light: rgb(139, 232, 223, 0.7);
$_dark_menu_item_background_active: rgb(139, 232, 223);
$_dark_menu_item_background_hover: rgba(30, 42, 51, 0.2);
$_container_border: rgba(139, 232, 223, 0.3);
$_container_border_warn: rgba(211, 47, 47, 0.6);

$_bg_light_50: #b3bcbe;
$_bg_light_100: #e5e8ec;
$_bg_light_200: #f1f3f6;

$_table_row_color: #54a3aa;

$_form_control_color: rgba(138, 173, 192, 1);
